// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // import Routes instead of Switch
import Home from './page/Home/Home';
import About from './page/About/About';
import Header from './component/Header/Header';
import Footer from './component/Footer/Footer';
import Contact from './page/ContactUs/Contact';
const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} /> // use element prop instead of component
        <Route path="/about" element={<About />} /> // use element prop instead of component
        <Route path="/contact" element={<Contact />} /> // use element prop instead of component
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;