// Footer.jsx
import React from 'react';
import { Link } from 'react-router-dom';
const Footer = () => {

    // create vatiable for current year
    const year = new Date().getFullYear();

    return (
        <footer className="row footer2">
            <div className="row m0 topFooter">
                <div className="container line2">
                    <div className="row">
                        <div className="col-sm-4 widget">
                            <div className="row m0">
                                <img src='new-assets/logo1.png' className='' style={{height: "100px"}} />
                                {/* <h4>About Sterling Diamonds</h4> */}
                                <p>Explore unparalleled luxury with Sterling Diamonds Hub, your ultimate destination for exquisite diamonds and jewelry, where every piece tells a story of elegance and sophistication.</p>
                            </div>
                        </div>
                        <div className="col-sm-4 widget">
                            <div className="row m0">
                                <h4>Help</h4>
                                <ul className="nav">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/about">About</Link></li>
                                    <li><Link to="/Contact">Contact Us</Link></li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-sm-3 widget">
                            <div className="row m0">
                                <h4>Connect With Us</h4>
                                <ul className="list-inline">
                                    <li><a href="index3.html#"><i className="fab fa-facebook-f" /></a></li>
                                    <li><a href="index3.html#"><i className="fab fa-twitter" /></a></li>
                                    <li><a href="index3.html#"><i className="fab fa-linkedin" /></a></li>
                                    <li><a href="index3.html#"><i className="fab fa-dribbble" /></a></li>
                                    <li><a href="index3.html#"><i className="fab fa-google-plus" /></a></li>
                                </ul>
                            </div>
                        </div> */}
                        <div className="col-sm-4 widget">
                            <div className="row m0">
                                <h4>Get Exclusive Offers and News</h4>
                                <form action="subscriptionList.php" method="post" role="form">
                                    <div className="input-group">
                                        <span className="input-group-addon"><i className="fas fa-envelope" /></span>
                                        <input type="email" className="form-control" id="subscribeEmail" name="subscribeEmail" placeholder="EMAIL ADDRESS" />
                                    </div>
                                    <input type="submit" className="form-control" defaultValue="Subscribe" id="submit" name="submit" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row m0 copyRight">
                <div className="container">
                    <div className="row">
                        <div className="fleft">© {year} <Link to="/">Sterling Diamonds Hub</Link> All Rights Reserved.</div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;