import React from 'react';
import './section.scss';
import { Link } from 'react-router-dom';
const SectionShowcaseSell = () => {

    return (
        <>
            {/* <section id="shopFeatures_new">
                <div className=" shopFeatures_new container">
                    <ul>
                        <li className="sf_first">
                            <img alt="" className="img-responsive" src="new-assets/showcase/1.png" />
                            <div className="sf_box">
                                <div className="sf_box_inner">
                                    <h2>Exceptional Quality</h2>
                                </div>
                            </div>
                        </li>
                        <li>
                            <Link href="/">
                                <img alt="" className="img-responsive" src="new-assets/showcase/2.png" />
                                <div className="sf_box">
                                    <div className="sf_box_inner">
                                        <h3>Certified & Ethically Sourced</h3>
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link href="/">
                                <img alt="" className="img-responsive" src="new-assets/showcase/3.png" />
                                <div className="sf_box">
                                    <div className="sf_box_inner">
                                        <h3>Personalized Consultation</h3>
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link href="/">
                                <img alt="" className="img-responsive" src="new-assets/showcase/4.png" />
                                <div className="sf_box">
                                    <div className="sf_box_inner">
                                        <h3>Investment Value</h3>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </section> */}

            {/* section with 4 div inside */}
            <section className='container'>
                <div className="section-showcase-sell">
                    <article className='reveal'>
                        <div className="card-showcase card-showcase-1">
                            <div className="card-showcase-text">
                                <h2>Exceptional Quality</h2>
                            </div>
                        </div>
                    </article>
                    <article className='reveal'>
                        <div className="card-showcase card-showcase-animation">
                            <div className="card-showcase-text">
                                <h2>Competitive Pricing</h2>
                            </div>
                        </div>
                    </article>
                    <article className='reveal'>
                        <div className="card-showcase card-showcase-animation">
                            <div className="card-showcase-text">
                                <h2>Personalized Consultation</h2>
                            </div>
                        </div>
                    </article>
                    <article className='reveal'>
                        <div className="card-showcase card-showcase-animation">
                            <div className="card-showcase-text">
                                <h2>Investment Value</h2>
                            </div>
                        </div>
                    </article>
                    <article className='reveal'>
                        <div className="card-showcase card-showcase-2">
                            <div className="card-showcase-text">
                                <h2>Certified & Ethically Sourced</h2>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </>

    );
}

export default SectionShowcaseSell;