import './contact.scss';
import React, { useState } from 'react';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Run your function here with the form data
        const proxyUrl = 'https://cors-anywhere.herokuapp.com/';

        try {
            const response = await fetch('https://script.google.com/macros/s/AKfycbzl8Kn92Cq7IqkrY2vHkNmV2Mp5hJYV565bmSDs0cClgoF47TveI75XaMRy9aUYG3Ft/exec', {
              method: 'POST',
              body: JSON.stringify(formData),
              headers: {
                'Content-Type': 'application/json',
              },
            });
        
            if (response.ok) {
              // Handle success (e.g., display a success message)
              console.log('Form data saved to Google Sheets');
              setFormData({
                name: '',
                email: '',
                subject: '',
                message: '',
              });
            } else {
              // Handle response errors
              console.error('Form data not saved');
            }
          } catch (error) {
            // Handle network errors
            console.error('Network error:', error);
          }
        
    };
    return (

        <>
            <section id="breadcrumbRow" className="row contact-us-hero">
                <h2>Contact Us</h2>
            </section>
            <section id="contactRow" className="row contentRowPad">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="row m0">
                                <h4 className="contactHeading heading">Send your query !</h4>
                            </div>
                            <div className="row m0 contactForm">
                                <form className="row m0" id="contactForm" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label htmlFor="name">Name *</label>
                                            <input type="text" required className="form-control" name="name" id="name" value={formData.name} onChange={handleChange} />
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="email">Email *</label>
                                            <input type="email" required className="form-control" name="email" id="email" value={formData.email} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="row m0">
                                        <label htmlFor="subject">Subject *</label>
                                        <input type="text" required className="form-control" name="subject" id="subject" value={formData.subject} onChange={handleChange} />
                                    </div>
                                    <div className="row m0">
                                        <label htmlFor="message">Your message</label>
                                        <textarea name="message"  id="message" className="form-control" value={formData.message} onChange={handleChange} />
                                    </div>
                                    <button className="btn btn-primary btn-lg filled" type="submit">Send message</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="row m0">
                                <h4 className="contactHeading heading">Contact info</h4>
                            </div>

                            <div className="media contactInfo">
                                <div className="media-left">
                                    <i className="fas fa-envelope" />
                                </div>
                                <div className="media-body">
                                    <h5 className="heading">Email us @</h5>
                                    <p>Email your issues and suggestion for the following email addresses: </p>
                                    <h5>Sterlingdimonds@gmail.com</h5>
                                </div>
                            </div> {/*contactInfo*/}
                            <div className="media contactInfo">
                                <div className="media-left">
                                    <i className="fas fa-phone" />
                                </div>
                                <div className="media-body">
                                    <h5 className="heading">need to call us?</h5>
                                    <p>US : +1 (713) 257-4467</p>
                                    <p>Canada : +1 (437) 262-9307</p>
                                    <p>India : +91 98243 61128</p>
                                </div>
                            </div> {/*contactInfo*/}
                            <div className="media contactInfo">
                                <div className="media-left">
                                    <i className="fas fa-question" />
                                </div>
                                <div className="media-body">
                                    <h5 className="heading">we have great support</h5>
                                    <p>We provide the best Quality of products to you.We are always here to help our lovely customers.We ship our products anywhere with more secure. We provide the best Quality of products to you.</p>
                                </div>
                            </div> {/*contactInfo*/}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contact;