import React from 'react';
import './section.scss';

const SectionTypeOfJewellery = () => {
    const jewellery = [
        {
            name: 'Engagement Rings',
            image: 'new-assets/products/engagement-ring.png'
        },
        {
            name: 'Wedding Rings',
            image: 'new-assets/products/wedding-ring.png'
        },
        {
            name: 'Earrings',
            image: 'new-assets/products/earing.png'
        },
        {
            name: 'Pendants',
            image: 'new-assets/products/pendants.png'
        },
        {
            name: 'Bracelets',
            image: 'new-assets/products/Bracelets.png'
        },
        {
            name: 'Necklaces',
             image: 'new-assets/products/Necklaces.png'
        }
    ];
    return (
        <section id="featureProducts" className="row contentRowPad">
            <div className="container">
                <div className="row sectionTitle">
                    <h2>Explore Sterling Diamonds Hub</h2>
                    {/* <h5>know more about our latest collection</h5> */}
                </div>
                

                <div className="jewellery-card-container">
                    {jewellery.map((value, index) => (
                        <div className="jewellery-card" key={index}>
                            <div className="jewellery-card-image">
                                <img src={value.image} alt=""/>
                            </div>
                            <h3>{value.name}</h3>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )

}


export default SectionTypeOfJewellery;
