import React from 'react';
import { Link } from 'react-router-dom'; // import Link

const Header = () => {
    return (
        <header className="row counter" id="header3">
            <div className="row m0 logo_line">
                <div className="container">
                    <div className="row ">
                        <div className="col-sm-6 logo ">
                            <Link to="/" className="logo_a">
                                <img src='new-assets/logo.png' style={{height: "60px"}} />
                                <span>Sterling Diamonds Hub</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-default m0 navbar-static-top">
                <div className="container-fluid container">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#mainNav">
                            <i className="fas fa-bars" /> MENU
                        </button>
                    </div>
                    <div className="collapse navbar-collapse" id="mainNav">
                        <ul className="nav navbar-nav">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/contact">Contact Us</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
