import React, { useEffect } from 'react';
import './hero.scss';
import { Link } from 'react-router-dom';
const Hero = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `assets/js/site.js`;


    script.onload = () => {
      window.testFunction();
    };

    document.body.appendChild(script);




  }, []); // Empty dependency array to run only once on mount
  return (
    <section id="slider" className="row">
      <div className="row sliderCont flexslider dark m0">
        <ul className="slides nav">
          <li className='background-li'>
            <video autoplay="true" loop muted  playsinline preload="auto" class="background-video" poster="">
              <source src="new-assets/videos/2.mp4" type="video/mp4" />
            </video>
            <div className="text_lines row m0">
              <div className="container p0 text-left">
                <h3>Where Quality Meets Brilliance</h3>
                <h2>Explore Our World of Exceptional Diamonds</h2>
              </div>
            </div>
          </li>
          <li className='background-li'>
            <video autoplay="true" loop muted playsinline preload="auto" class="background-video" poster="">
              <source src="new-assets/videos/1.mp4" type="video/mp4" />
            </video>
            <div className="text_lines row m0">
              <div className="container p0 text-left">
                <h3>From Timeless Classics to Modern Marvels</h3>
                <h2>Discover Jewelry That Tells Your Story.</h2>
                <h4><Link className="theme_btn with_i" to="/contact"><i className="fas fa-plus-circle" />Contact Us</Link></h4>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Hero;
