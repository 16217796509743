// create react component Section
import React, { useState } from 'react';
import './section.scss';
const SectionDiamondCatelog = () => {
  // create array of svg filenames 
  const svgFiles = ["round","princess","radiant","asscher","cushion","oval","emerald","pear","marquise","heart","rosecut","old european","halfmoon","baguette","hexagon","oldmine","trapezoid","triangular"];

  const [toggle,setToogle] = useState(false);

  const toggleDiv = () => {
    setToogle(!toggle);
    }
  

  



  return (
    <section id="featureProducts" className="row contentRowPad">
      <div className="container" style={{boxSizing:"border-box"}}>
        <div className="row sectionTitle">
          <h2>Discover Diamonds by Shape</h2>
          {/* <h5>know more about our latest collection</h5> */}
        </div>
        {/* add .show class if toggle is true */}
        <div className={`grid-container gradientDiamond ${toggle ? 'showDiamonds' : ''} `}>
        
          {svgFiles.map((svgFile, index) => (
            <div key={index} className="col-sm-2 product diamond-icon">
              <img src={`new-assets/svg/${svgFile}.svg`} alt={svgFile} />
              <p className="diamond-title">{svgFile}</p>
            </div>
          ))}
          <button onClick={toggleDiv} className={`show-btn fa fa-chevron-down ${toggle ? 'rotate' : ''}`}></button>
        </div>
      </div>
    </section>
  );
}

export default SectionDiamondCatelog;