// Home.jsx
import React from 'react';
import Hero from '../../component/Hero/Hero';
import SectionDiamondCatelog from '../../component/Section/SectionDiamondCatelog';
import SectionShowcaseSell from '../../component/Section/SectionShowcaseSell';
import SectionTypeOfJewellery from '../../component/Section/SectionTypeOfJewellery';
const Home = () => {
    return (
        <>
        <Hero />
        <SectionDiamondCatelog />
        <SectionShowcaseSell/>
        <SectionTypeOfJewellery/>
        </>
    );
}

export default Home;