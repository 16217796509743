// About.jsx
import React from 'react';
import './about.scss';
const About = () => {
    return (
        <>
            <section id="breadcrumbRow" className="row">
                <h2>Our Story</h2>
            </section>
            <section id="whatWeDid" className="row contentRowPad content-about">
                <div className="container">
                    <div className="row">
                        <h2 className='about-title'>Crafting Elegance: Our Journey in the World of Fine Diamonds</h2>
                        <div className="col-sm-6 about-image">
                            <img src="new-assets/products/about-us.png" alt="" />
                        </div>
                        <div className="col-sm-6 about-section">
                            <p>Welcome to Sterling Diamonds Hub, where every gem tells a story of unmatched brilliance and timeless elegance. Founded just three years ago, we've quickly established ourselves as connoisseurs of both earth-grown and lab-created diamonds, catering to a discerning clientele across the United States, Canada, and India.</p>
                            <p>Our journey began with a simple vision: to make the world of fine diamonds more accessible, more transparent, and more affordable. We've always believed that every diamond should be a reflection of your unique style and personality, and that's why we've made it our mission to offer a diverse range of diamonds that cater to every taste and budget.</p>
                            <p>As we continue to grow and expand our reach, our mission remains the same: to provide our clients with exceptional jewelry that celebrates life's most precious moments. At Sterling Diamonds Hub, we're not just selling diamonds; we're creating lasting memories, one sparkling gem at a time.</p>
                        </div>
                    </div>

                </div>
            </section>
            <section id="hww" class="row contentRowPad">
                <div class="container container-why-us">
                    <h3 className='about-title'>Why buy from us ?</h3>
                    <div class="col-sm-4">
                        <h5>Unrivaled Quality and Authenticity</h5>
                        <p>We pride ourselves on offering diamonds of unparalleled quality. Each gem is meticulously inspected and selected to ensure it meets our stringent standards for brilliance, clarity, cut, and color.</p>
                    </div>
                    <div class="col-sm-4">
                        <h5>Global Reach, Local Touch</h5>
                        <p>With operations in the US, Canada, and India, we bring a global perspective to our craft while staying grounded in local traditions of jewelry making. This unique blend allows us to offer diverse designs.</p>
                    </div>
                    <div class="col-sm-4">
                        <h5>Exceptional Customer Service</h5>
                        <p>Our relationship with you goes beyond the transaction. We offer personalized consultations, comprehensive after-sales support, and guarantee on our diamonds, ensuring your satisfaction and peace of mind.</p>
                    </div>
                </div>
            </section>
            <section id="hww" class="row contentRowPad">
                <div class="container container-why-us">
                    <h3 className='about-title'>Our Location</h3>
                    <div class="col-sm-4">
                        <div className='card-location us'>
                            <h5>United States</h5>
                        </div>
                    </div>
                    <div class="col-sm-4">
                    <div className='card-location canada'>
                        <h5>Canada</h5>
                    </div>
                    </div>
                    <div class="col-sm-4">
                    <div className='card-location india'>

                        <h5>India</h5>
                    </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default About;